import * as React from "react"
import Layout from "../components/layout"
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useEffect } from "react";
import '../components/index.css'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { registerBeta, queryFoodInformation, getSuggestions } from '../utils/api'
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { Link } from 'gatsby'
import LockIcon from '@mui/icons-material/Lock';
import Collapse from '@mui/material/Collapse';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  maxWidth: "calc(90% - 64px)",
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const pageStyles = {
  color: "#232129",
  padding: "96px 12px",
  fontFamily: "Roboto, sans-serif, serif",
  maxWidth: "45rem",
  margin: "auto",
  position: "relative",
  minHeight: "70vh"
}


function SkeletonFoodCard() {
  return (
    <Card style={{ padding: "0px" }}>
      <Stack spacing={1}>
        <Skeleton variant="rounded" height={100} animation="wave" />
        <Skeleton variant="rounded" height={100} animation="wave" />
      </Stack>
    </Card>
  );
}


function SkeletonPicturesRow({ pictures }) {
  const [open, setOpen] = React.useState(false)
  const [pictureI, setPictureI] = React.useState(0)

  const handleOpen = (pi) => {
    setPictureI(pi)
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false)
  }

  console.log("Pictures", pictures)

  return <div style={{
    display: "flex",
    overflow: "auto"
  }}>
    {pictures.map((p, pi) => <CardMedia
      key={pictures.alt + pi}
      component="img"
      alt={pictures.alt}
      height="70"
      width="70"
      image={p["src"]["tiny"]}
      sx={{ cursor: "pointer" }}
      onClick={() => handleOpen(pi)} />
    )}
    {pictures?.length > 0 && <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <ClearIcon onClick={handleClose} sx={{ position: "absolute", top: "0.25rem", right: "0.25rem", cursor: "pointer" }} />
        <div style={{
          maxWidth: "100%",
          maxHeight: "100%",
          width: "30rem",
          height: "30rem",
          background: `url(${pictures[pictureI]["src"]["original"]}) center / contain no-repeat`
        }}></div>
      </Box>
    </Modal>}
  </div>
}

/*
function CodeBlock() {
  return <div>
    <div>
      <span>GET</span>
      <span>https://api.thefoodapi.com/food/id-123456/</span>
    </div>
    <div>
      <span>Authorization:</span>
      <span>Basic YWxhZGRpbjpvcGVuc2VzYW1l</span>
    </div>
  </div>
}
*/

function EmptyFoodCard({ searchValue }) {
  const [email, setEmail] = React.useState("")
  const [sending, setSending] = React.useState(false)
  const [success, setSuccess] = React.useState(null)

  return <div>
    <p>We haven't launched our full API yet.</p>
    <p>Get notified when we launch, and we'll do our best to include <b>{searchValue}</b>.</p>
    <div style={{ display: "flex" }}>
      <div style={{
        width: "100%",
      }}>
        <TextField
          id="email"
          fullWidth
          aria-describedby="email-helper-text"
          placeholder="Email"
          type="email"
          value={email}
          inputProps={{
            'aria-label': 'email',
          }}
          onChange={(e) => setEmail(e.target.value)} />
        <FormHelperText id="email-helper-text">Your email won't be shared with third parties.</FormHelperText>
      </div>
      <div className="clickable" style={{
        padding: "0 8px",
        height: "calc(35px + 1.25rem)",
        borderRadius: "4px",
        background: "#007788",
        color: "white",
        display: "flex",
        whiteSpace: "nowrap",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        marginLeft: "0.5rem",
        position: "relative",
        overflow: "hidden"
      }} onClick={() => {
        if (email) {
          registerBeta({ email, origin: "/api" }).then(resp => {
            if (resp.status === 200) {
              setSuccess(true)
            } else {
              setSuccess(false)
            }
            setSending(false)
          })
        }
      }}><b>Get notified</b>
        {sending && <div style={{ position: "absolute", inset: "0", background: "inherit", display: 'flex', alignItems: "center", justifyContent: "center" }}><b>Sending...</b></div>}
        {success && <div style={{ position: "absolute", inset: "0", background: "inherit", display: 'flex', alignItems: "center", justifyContent: "center" }}><b>Success!</b></div>}
      </div>
    </div>
    {(success !== null && !success) && <div style={{ color: "red" }}>Something went wrong. Please try again later.</div>}
    <p>Want to know more about what we're building? Check out our <Link to="/roadmap" style={{ textDecoration: "none" }}>roadmap</Link>.</p>
  </div >;
}


function FoodCard({ foodData }) {
  const [showPictures, setShowPictures] = React.useState(false)
  const [showAPICode, setShowAPICode] = React.useState(false)
  const [email, setEmail] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [imageOpen, setImageOpen] = React.useState(false);
  const [sendingRequest, setSendingRequest] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const [imageIsLoading, setImageIsLoading] = React.useState(true);
  const [image, setImage] = React.useState({});
  const handleImageLoaded = () => {
    setImageIsLoading(false);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setSuccess(null)
  }
  const handleImageOpen = () => setImageOpen(true);
  const handleImageClose = () => {
    setImageOpen(false)
  }


  useEffect(() => {
    const image = new Image();
    image.onload = handleImageLoaded;
    image.src = foodData.protected ? "https://www.investopedia.com/thmb/QJ5mblk1h2VmWFl5Z77eiP5JC6A=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/lockedin-d2670e799df5407dbce235c22ed38152.jpg" : (foodData.currated_images?.length > 0 ? foodData.currated_images[0]["src"]["original"] : null);
    setImage(image);
  }, []);

  return <>
    <Modal
      open={imageOpen}
      onClose={handleImageClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <ClearIcon onClick={handleImageClose} sx={{ position: "absolute", top: "0.25rem", right: "0.25rem", cursor: "pointer" }} />
        <div style={{
          maxWidth: "100%",
          maxHeight: "100%",
          width: "30rem",
          height: "30rem",
          background: foodData.protected ? "https://www.investopedia.com/thmb/QJ5mblk1h2VmWFl5Z77eiP5JC6A=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/lockedin-d2670e799df5407dbce235c22ed38152.jpg" : (foodData.currated_images?.length > 0 ? `url(${foodData.currated_images[0]["src"]["original"]}) center / contain no-repeat` : null)
        }}></div>
      </Box>
    </Modal>
    <Card style={{ position: "relative" }}>
      {imageIsLoading && <Skeleton variant="rounded" height={140} animation="wave" />}
      {!imageIsLoading && (
        (foodData.protected ?
          <div style={{ height: "140px", width: "100%", background: "black", color: "white", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <div><LockIcon /></div>
            <div><Link to="/signup" style={{ color: "white", textUnderlineOffset: "4px" }}>Create account</Link> to view.</div>
          </div> :
          <CardMedia
            sx={{ cursor: "pointer" }}
            onClick={handleImageOpen}
            component="img"
            alt={foodData.protected ? "Access restricted" : (foodData.currated_images?.length > 0 ? foodData.currated_images[0]["alt"] : null)}
            height="140"
            width="100%"
            image={foodData.protected ? "https://www.investopedia.com/thmb/QJ5mblk1h2VmWFl5Z77eiP5JC6A=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/lockedin-d2670e799df5407dbce235c22ed38152.jpg" : (foodData.currated_images?.length > 0 ? foodData.currated_images[0]["src"]["original"] : null)}
          // TODO: no image picture 
          // TODO: use config file"/static/images/burger/001.jpg"
          // TODO: use images I saved http://localhost:3003/images/
          />
        )
      )}
      {(foodData.currated_images?.length > 1) &&
        <div
          style={{
            position: "absolute",
            right: "5px",
            top: "calc(140px - 1rem - 2px)",
            borderRadius: "50%",
            display: "flex",
            background: "white",
            padding: "0.25rem",
            border: "1px solid #007788",
            cursor: "pointer"
          }}
          onClick={() => setShowPictures(!showPictures)}
        >
          <UnfoldMoreIcon style={{
            fill: "#007788",
            width: "1.5rem",
            height: "1.5rem",
            zIndex: "1"
          }} />
        </div>}
      <div className={"collapsible" + (showPictures ? " open" : " closed")}
        style={{
          maxHeight: "70px"
        }}>
        <SkeletonPicturesRow pictures={foodData.currated_images?.length > 1 ? foodData.currated_images.slice(1, foodData.currated_images.length) : []} />
      </div>
      <CardContent>
        <div style={{ display: "flex" }}>
          <Typography gutterBottom variant="h5" component="div">
            {foodData.display_name}
          </Typography>
          <div style={{ display: "flex", alignItems: 'center', marginLeft: "1rem", marginBottom: "0.6em", lineHeight: "1.334" }}>
            <div style={{
              padding: "0.125rem 0.5rem",
              background: "#AEE67B",
              borderRadius: "8px",
              fontSize: "80%"
            }}>{foodData.dish_category || "Access restricted"}</div>
          </div>
        </div>
        <Typography variant="body2" color="text.secondary">
          {foodData.description || "Access restricted"}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" style={{ display: "flex", alignItems: "center", color: "#007788" }} onClick={() => setShowAPICode(true)}>API code</Button>
      </CardActions>
      <Collapse in={showAPICode} timeout="auto" unmountOnExit>
        <CardContent sx={{ background: "#00778810" }}>
          <code style={{ whiteSpace: "pre-wrap", fontSize: "14px" }}>{`/GET https://api.thefoodapi.com/info/${encodeURI(foodData.display_name).toLowerCase()}
Header: "Authorization: Bearer <YOUR API KEY>"`}
          </code>
          <Link to='/signup' style={{ textDecoration: "none", color: "white", marginTop: "1rem", display: "flex" }}><div style={{ padding: "0.5rem 1rem", background: "#007788", borderRadius: "4px", fontWeight: "600" }}>Sign up to get your own API key</div></Link>
        </CardContent>
      </Collapse>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ClearIcon onClick={handleClose} sx={{ position: "absolute", top: "0.25rem", right: "0.25rem", cursor: "pointer" }} />
          {(sendingRequest) && <div style={{
            position: "absolute",
            inset: "0",
            backdropFilter: "brightness(0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "2",
            color: "white",
            fontWeight: "bold"
          }}>Sending</div>}
          {success && <div style={{
            position: "absolute",
            inset: "0",
            background: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "2",
            padding: "1rem",
            textAlign: "center"
          }}>
            <h2 style={{ color: "rgb(0, 119, 136)" }}>Success</h2>
            <div style={{ paddingBottom: "4rem" }}>Thank you for joining the Beta user list. We will come back to you shortly.</div>
          </div>}
          <div style={{ fontSize: "150%", marginBottom: "2rem" }}><b>The API is not yet public 🙈</b></div>
          <div>We are looking for Beta testers. If you are interested in early access to our API, leave us your email address and we will contact you shortly.</div>
          <TextField label="Email" fullwidth style={{ width: "100%", marginTop: "1rem", marginBottom: "1.5rem" }} onChange={(e) => setEmail(e.target.value)} />
          <div className="clickable" style={{
            background: "#007788",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "1rem 0",
            color: "white",
            borderRadius: "4px",
            fontWeight: "bold",
            marginBottom: "0.5rem"
          }} onClick={() => {
            if (email) {
              setSendingRequest(true)
              registerBeta({ email, origin: "/" }).then(resp => {
                if (resp.status === 200) {
                  setSuccess(true)
                  setEmail(null)
                }
                else setSuccess(false)
                setSendingRequest(false)
              })
            }
          }}>Send request</div>
          {(success !== null && !success) && <div style={{ color: "red" }}>Something went wrong. Please try again later.</div>}
          <div style={{
            color: "#00778875",
            textAlign: "center",
            padding: "1rem 0"
          }}>Or</div>
          <a className="clickable" href="https://discord.gg/ekEGYrY4" target="_blank" style={{
            textDecoration: "none",
            color: "white",
            background: "#7289da",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem 0",
            color: "white",
            borderRadius: "4px",
            fontWeight: "bold",
            marginBottom: "0.5rem"
          }}>
            <span style={{ marginRight: "0.5rem" }}>Join our discord server (NEW)</span>
            <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" height="1.25rem" width="1.25rem" xmlns="http://www.w3.org/2000/svg" ><path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path></svg>
          </a>
          <div style={{
            marginTop: "2rem",
            textAlign: "center",
          }}>
            <a
              style={{
                color: "#00778875"
              }}
              href="https://www.privacypolicygenerator.info/live.php?token=fziOD65GhSRDzONVqJcFxdCxZYBSMwDc" target="_blank" alt="Privace policy">Privacy policy</a></div>
        </Box>
      </Modal>
    </Card>
    {/* <div style={{ fontSize: "80%" }}>All rights reserved. <span className="contact-us" onClick={handleOpen}>Contact us</span> to get a commercial license.</div> */}
  </>
}

const cheeseBurgerInfo = {
  currated_images: [{
    src: {
      original: "/static/images/burger/001.jpg"
    }
  },
  {
    src: {
      tiny: "/static/images/burger/002.jpg",
      original: "/static/images/burger/002.jpg"
    }
  },
  {
    src: {
      tiny: "/static/images/burger/003.jpg",
      original: "/static/images/burger/003.jpg"
    }
  },
  {
    src: {
      tiny: "/static/images/burger/004.jpg",
      original: "/static/images/burger/004.jpg"
    }
  }],
  description: "Burger topped with delicious slice of cheese, melted over the patty.",
  dish_category: "burger",
  display_name: "Cheese burger"
}

const IndexPage = ({ location }) => {
  const [firstMount, setFirstMount] = React.useState(true)
  const [value, setValue] = React.useState({ "title": "Cheese burger" });
  const [search, setSearch] = React.useState("");
  const [isFocused, setIsFocused] = React.useState(null);
  const [valueToSearch, setValueToSearch] = React.useState({ "title": "Cheese burger" });
  // const [valueSearched, setValueSearched] = React.useState({ "title": "Cheese burger" });
  const [searchResult, setSearchResult] = React.useState(cheeseBurgerInfo)
  const [suggestions, setSuggestions] = React.useState([]);
  const [showSuggestions, setShowSuggestions] = React.useState(false);
  const [searching, setSearching] = React.useState(false);

  useEffect(() => {
    setFirstMount(false)
  }, [])

  useEffect(() => {
    if (!firstMount) {
      // TODO: sending search to API -> value
      setSearching(true)
      setShowSuggestions(false)
      setValue(valueToSearch)
      // setValueSearched(valueToSearch)

      queryFoodInformation({ userQuery: valueToSearch }).then(
        resp => {
          if (resp.status === 200) {
            resp.json().then(
              data => {
                if (data.length > 0) {
                  setSearchResult(data[0])
                  setSearching(false)
                } else {
                  setSearchResult(null)
                  setSearching(false)
                }
              }
            )
          } else {
            setSearchResult(null)
            setSearching(false)
          }
        }
      )
    }
  }, [search])

  useEffect(() => {
    if (!firstMount && !searching) getSuggestions({ value: value.title }).then(resp => {
      if (resp.status === 200) {
        resp.json().then(data => {
          setSuggestions(data.map(v => { return { title: v["display_name"], code_name: v["code_name"] } }))
          setShowSuggestions(true)
        })
      }
    })
  }, [value])

  useEffect(() => {
    setValueToSearch(value)
  }, [value])

  return (
    <Layout location={location}>
      <div style={pageStyles}
      >
        <div
          style={{
            width: "100%",
            position: "relative"
          }}>
          <TextField
            fullWidth
            onFocus={() => setIsFocused("search input")}
            onBlur={() => setIsFocused(null)}
            id="search-bar"
            placeholder="Search"
            variant="outlined"
            sx={{ zIndex: "1" }}
            value={value.title}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setSearch(Date.now())
                e.preventDefault();
              }
            }}
            onChange={(event) => {
              setValue({ title: event.target.value || "", code_name: "" })
            }}
            inputRef={input => (isFocused === "search input") && input && input.focus()}
            InputProps={{
              endAdornment: (isFocused === "search input") ? <InputAdornment position="start"><SearchIcon style={{ cursor: "pointer" }} onClick={(e) => { setSearch(Date.now()) }} /></InputAdornment> :
                <InputAdornment position="start"><ClearIcon style={{ cursor: "pointer" }} onClick={(e) => { setValue({ title: "", code_name: "" }); setIsFocused("search input") }} /></InputAdornment>,
            }}
          />
          {showSuggestions && <>
            <div style={{
              position: "fixed",
              inset: "0",
            }}
              onClick={() => setShowSuggestions(false)}></div>
            <div className="suggestions"
              onClick={(e) => e.stopPropagation()}>
              {suggestions.map((sugg, i) => {
                return <div
                  onClick={(e) => {
                    setValueToSearch({ title: sugg.title || "", code_name: sugg.code_name }) // TODO: why not just sugg ?
                    setSearch(Date.now())
                  }}
                  key={`sugg-${i}`}
                  dangerouslySetInnerHTML={{ __html: sugg.title.replace(new RegExp(value.title, "gi"), (match) => `<mark>${match}</mark>`) }}></div>
              })}
              <div
                onClick={(e) => setSearch(Date.now())}
                key={`sugg-search`}
              ><i>Search: {valueToSearch.title}</i></div>
            </div>
          </>
          }
        </div>
        <div className="query-result">
          {searching ? <SkeletonFoodCard /> : (
            searchResult != null ? <FoodCard foodData={searchResult} /> : <EmptyFoodCard searchValue={valueToSearch.title} />
          )}
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>Home Page</title>
